'use client'
import {
  useState,
  useMemo,
  useRef,
  useEffect,
} from 'react'
import {
  useRefinementList,
  useGeoSearch,
  useClearRefinements,
} from 'react-instantsearch'
import { useOnClickOutside } from 'usehooks-ts'
import qs from 'qs'
import { useRouter } from 'next/navigation'

import {
  AdvSearchSearchInput as Wrapper,
  AdvSearchSearchInputDropDownItem as Option,
  AdvSearchNoResults,
  AdvSearchSearchInputViewAllLocations,
} from '@/components/devlink'

const INITIAL_PLACEHOLDER = 'Search by city or state'

const indexName = process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME || ''

type RefinementListProps = {
  attribute: string
}

type RefinementListItem = {
  label: string,
  value: string,
  isRefined: boolean,
}

export function RefinementList({
  attribute
}: RefinementListProps) {
  const router = useRouter()
  const {
    items,
    searchForItems,
    refine,
  } = useRefinementList({ attribute })
  const {
    refine: clearRefinementList,
  } = useClearRefinements({ includedAttributes: [attribute] })
  const {
    clearMapRefinement,
    currentRefinement: isRefinedWithMap,
  } = useGeoSearch()
  const showViewAllLocations = useMemo(() => {
    return isRefinedWithMap ? true : false
  }, [isRefinedWithMap])

  const currentRefinement = items.find((item: RefinementListItem) => item.isRefined)
  const clear = () => {
    clearMapRefinement()
    clearRefinementList()
  }

  const updateUiState = (item?: RefinementListItem) => {
    clear()

    if (item) {
      refine(item.value)
    }
  }

  const placeholder = useMemo(() => currentRefinement ? currentRefinement.label : INITIAL_PLACEHOLDER, [currentRefinement])
  const [value, setValue] = useState('')
  const [showList, setShowList] = useState(false)

  useEffect(() => {
    if (isRefinedWithMap) {
      setValue('')
      searchForItems('')
    }
  }, [isRefinedWithMap])

  const ref = useRef(null)
  useOnClickOutside(ref, () => {
    setValue('')
    searchForItems('')
    setShowList(false)
  })

  const wrapperProps = {
    showList,
    showClearButton: showList,
    clearSearchProps: {
      onClick: (e: any) => {
        e.preventDefault()
        setValue('')
        searchForItems('')
        setShowList(false)
      },
    },
    searchInputProps: {
      id: 'location-search',
      placeholder,
      value,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value)
        searchForItems(e.target.value)
      },
      onFocus: () => setShowList(true),
    },
  }

  const handleSelect = (item: RefinementListItem) => {
    updateUiState(item)
    setValue('')
    setShowList(false)
  }

  return <div ref={ref} className='location-group'>
    <Wrapper {...wrapperProps}>
      {items.length > 0
        ? items.map((item: RefinementListItem) =>
            <Option
              key={item.label}
              showEnabled={item.isRefined}
              showDisabled={!item.isRefined}
              text={item.label}
              buttonProps={{
                onClick: (e: any) => {
                  e.preventDefault()
                  handleSelect(item)
                },
              }}
            />
        )
        : <AdvSearchNoResults />
      }
      {showViewAllLocations ?
        <AdvSearchSearchInputViewAllLocations
          buttonProps={{
            onClick: () => {
              clearMapRefinement()
              setValue('')
              searchForItems('')
            }
          }}
        />
      : null}
    </Wrapper>
  </div>
}
