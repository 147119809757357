'use client'
import { useState } from 'react'
import { useRefinementList } from 'react-instantsearch'

import {
  AdvSearchBedroomGroup as ButtonGroup,
  AdvSearchBedroomGroupButtonV2 as Button,
} from '@/components/devlink'

type ButtonGroupRefinementListProps = {
  formatLabel: (label: string) => string,
  attribute: string,
  sortBy: any[],
}

export function ButtonGroupRefinementList({
  formatLabel,
  attribute,
  sortBy
}: ButtonGroupRefinementListProps) {
  const {
    items,
    refine,
  } = useRefinementList({ attribute, sortBy })

  return <ButtonGroup>
    {items.map(item =>
      <Button
        key={item.label}
        buttonProps={{
          onClick: () => refine(item.value),
        }}
        showActive={item.isRefined}
        showInActive={!item.isRefined}
        text={formatLabel ? formatLabel(item.label) : item.label}
      />
    )}
  </ButtonGroup>
}

