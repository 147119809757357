'use client'
import { useState, useEffect } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

import * as animations from './animations'
import useScrollLock from './useScrollLock'

type Animation = 'slideFromLeft' | 'appear'

export function useModal (target: string, animation: Animation = 'slideFromLeft', preventDefault: boolean = true) {
  const [show, setShow] = useState<boolean>(false)
  const { lock, unlock } = useScrollLock({ autoLock: false })

  const open = () => setShow(true)
  const close = () => setShow(false)
  const toggle = (e: any) => {
    if (preventDefault) e.preventDefault()
    setShow(!show)
  }

  useHotkeys('esc', close, {
    enableOnFormTags: ['input'],
  })

  useEffect(() => {
    const { setup, cleanup } = animations[animation]

    setup(target)

    return () => cleanup(target)
  }, [animation])

  useEffect(() => {
    const { forward, reverse } = animations[animation]

    if (show) {
      forward(target)
      lock()
    } else {
      reverse(target)
      unlock()
    }

    return () => {
      reverse(target)
      unlock()
    }
  }, [show, animation])

  return {
    isOpen: show,
    open,
    close,
    toggle,
  }
}
