// @ts-nocheck
// THIS CODE WAS COPIED FROM @/components/devlink/_Builtin/Form
import React from "react";
function onKeyDownInputHandlers(e) {
  e.stopPropagation();
}
const HIDE_DEFAULT_INPUT_STYLES = {
  opacity: 0,
  position: "absolute",
  zIndex: -1,
};
const CHECKED_CLASS = "w--redirected-checked";
const FOCUSED_CLASS = "w--redirected-focus";
const FOCUSED_VISIBLE_CLASS = "w--redirected-focus-visible";
export function FormBooleanInput({
  className = "",
  checked = false,
  type = "checkbox",
  inputType,
  customClassName,
  ...props
}) {
  const [isChecked, setIsChecked] = React.useState(checked);
  // START - custom code to let the parent component control the checked state
  React.useEffect(() => {
    if (props.onChange) {
      setIsChecked(checked)
    }
  }, [checked]);
  // END - custom code to let the parent component control the checked state
  const [isFocused, setIsFocused] = React.useState(false);
  const [isFocusedVisible, setIsFocusedVisible] = React.useState(false);
  const wasClicked = React.useRef(false);
  const inputProps = {
    checked: isChecked,
    type,
    onChange: (e) => {
      // NOTE - if onChange is defined, then checked state is controlled by the parent component
      if (props.onChange) props.onChange(e);
      else setIsChecked((prevIsChecked) => !prevIsChecked);
    },
    onClick: (e) => {
      if (props.onClick) props.onClick(e);
      wasClicked.current = true;
    },
    onFocus: (e) => {
      if (props.onFocus) props.onFocus(e);
      setIsFocused(true);
      if (!wasClicked.current) {
        setIsFocusedVisible(true);
      }
    },
    onBlur: (e) => {
      if (props.onBlur) props.onBlur(e);
      setIsFocused(false);
      setIsFocusedVisible(false);
      wasClicked.current = false;
    },
    onKeyDown: onKeyDownInputHandlers,
  };
  if (inputType === "custom") {
    const pseudoModeClasses = `${isChecked ? ` ${CHECKED_CLASS}` : ""}${
      isFocused ? ` ${FOCUSED_CLASS}` : ""
    }${isFocusedVisible ? ` ${FOCUSED_CLASS} ${FOCUSED_VISIBLE_CLASS}` : ""} ${
      customClassName ?? ""
    }`;
    const currentClassName = `${className}${pseudoModeClasses}`;
    return (
      <>
        <div className={currentClassName} />
        <input {...props} {...inputProps} style={HIDE_DEFAULT_INPUT_STYLES} />
      </>
    );
  }
  return <input className={className} {...props} {...inputProps} />;
}
export function FormCheckboxInput({ className = "", ...props }) {
  return (
    <FormBooleanInput
      {...props}
      type="checkbox"
      className={className + " w-checkbox-input"}
    />
  );
}
