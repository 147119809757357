'use client'
import * as React from 'react';
import { Range, getTrackBackground } from 'react-range';

const STEP = 1.0;

type OnChangeFunctionArgs = {
  values: number[];
}

type RangeSliderProps = {
  rtl?: boolean;
  min: number;
  max: number;
  values: number[];
  onValuesUpdated: (args: OnChangeFunctionArgs) => void;
  onChange: (args: OnChangeFunctionArgs) => void;
}

const RangeSlider: React.FC<RangeSliderProps> = ({
  rtl,
  min,
  max,
  values,
  onValuesUpdated,
  onChange,
}) => {
  return (
    <div className='rheostat rheostat_1'>
      <Range
        values={values}
        step={STEP}
        min={min}
        max={max}
        rtl={rtl}
        onChange={values => onValuesUpdated({ values })}
        onFinalChange={values => onChange({ values })}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            className='DefaultBackground'
            style={props.style}
          >
            <div
              ref={props.ref}
              className='DefaultProgressBar_progressBar'
              style={{
                background: getTrackBackground({
                  values,
                  colors: ['#eee', '#548BF4', '#eee'],
                  min,
                  max,
                  rtl
                }),
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props: { key, ...props }, isDragged }) => (
          <div
            key={key}
            {...props}
            className='DefaultHandle_handle'
          >
          </div>
        )}
      />
    </div>
  );
};

export default RangeSlider;
