type Address = {
  line1: string
  city: string
  state: string
}

export function formatAddress(address: Address) {
  return `${address.line1} ${address.city}, ${address.state}`
}

export function formatPrice(price: number) {
  return price.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 })
}

export function formatBedrooms(bedrooms: number[]) {
  return bedrooms.reduce((acc, next, i) => {
    const str = next === 0 ? 'Studio' : next

    if (i === 0) {
      return acc + str

    } else {
      return acc + (i === bedrooms.length - 1 ? ' & ' : ', ') + str

    }

  }, '')
}

