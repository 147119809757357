'use client'
import { useState } from 'react'
import { Index, Configure } from 'react-instantsearch'
import Image from 'next/image';
import LazyLoad from '@/components/LazyLoad'
import Skeleton from '@/components/Skeleton'
import { SearchInputHomePage } from '@/components/devlink'
import { useAutocomplete } from './hooks'

import {
  SearchModalResultCommunityAndMarket,
  SearchInputPopoverHeading,
  SearchInputNoResults,
} from '@/components/devlink'

import type {
  ApartmentHit,
  MarketHit,
  HighlightResult,
} from './types'

const indexName = process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME

const ApartmentHit = (hit: ApartmentHit) => {
  const line1 = <div dangerouslySetInnerHTML={{ __html: hit._highlightResult.name.value }}></div>

  const markets = hit._highlightResult.allMarkets || []

  const bestLine2 = markets.reduce((acc: HighlightResult, next: HighlightResult) => {
    if (
      (next.fullyHighlighted && !acc.fullyHighlighted)
      || (next.matchLevel === 'full' && acc.matchLevel !== 'full')
      || (next.matchedWords.length > acc.matchedWords.length)
    ) {
      return next

    } else {
      return acc
    }
  })
  const line2 = bestLine2 ? <div dangerouslySetInnerHTML={{ __html: bestLine2.value.replace(/^- */, '') }}></div> : null

  const [image] = hit.tour.length > 0
    ? hit.tour
    : (hit.carousel || [hit.defaultPhoto])

  return <SearchModalResultCommunityAndMarket
    line1Slot={line1}
    line2Slot={line2}
    linkProps={{ href: hit.marketingWebsiteUrl, target: '_blank' }}
    showImage={true}
    imageSlot={
      <LazyLoad key={image.url} placeholder={<Skeleton />}>
      <Image
        className='search-modal---image---slot-class'
        src={image.url}
        alt={image.alt}
        fill={true}
        sizes='100px'
      />
      </LazyLoad>
    }
  />
}

const MarketHit = (hit: MarketHit) => {
  const line1 = <div dangerouslySetInnerHTML={{ __html: hit._highlightResult.line1.value }}></div>
  const line2 = <div dangerouslySetInnerHTML={{ __html: hit._highlightResult.line2.value }}></div>

  return <SearchModalResultCommunityAndMarket
    line1Slot={line1}
    line2Slot={line2}
    linkProps={{ href: `/${hit.slug}/` }}
  />
}

export function SearchInput() {
  const { indices, refine } = useAutocomplete();
  const [value, setValue] = useState('')

  const props = {
    showPopover: true,
    showClear: value.length > 0,
    inputProps: {
      value,
      onChange: (e: any) => {
        setValue(e.target.value)
        refine(e.target.value)
      },
    },
    clearSearchProps: {
      onClick: () => {
        setValue('')
        refine('')
      },
    }
  }

  const indexArray = [
    {
      indexName: `${indexName}__PAGES`,
      heading: 'Our Locations',
      Component: MarketHit,
    },
    {
      indexName,
      heading: 'Our Communities',
      Component: ApartmentHit,
    },
  ]
  const children = indexArray.reduce((acc, next, i) => {
      const { indexName, heading, Component } = next
      const index = indices.find(index => index.indexName === indexName)
      const hits = index?.hits || []
      if (hits.length === 0) return acc

      return [
        ...acc,
        <SearchInputPopoverHeading key={indexName} text={heading} />,
        ...hits.map(hit =>
          <Component key={hit.objectID} {...hit} />
        )
      ]
    }, [] as React.ReactNode[])

  return <SearchInputHomePage {...props}>
    <Configure hitsPerPage={13} />
    <Index indexName={`${indexName}__PAGES`}></Index>
    {children.length > 0 ? children : <SearchInputNoResults />}
  </SearchInputHomePage>
}
