const LATITUDE = {
  MIN: -90,
  MAX: 90,
}
const LONGITUDE = {
  MIN: -180,
  MAX: 180,
}

const offset = 0.001

export const getBoundingBox = (apartments: any[]) => {
  if (apartments.length === 1) {
    const { lat, lng } = apartments[0].coordinates
    return [
      [Math.max(lng-offset, LONGITUDE.MIN), Math.max(lat-offset, LATITUDE.MIN)],
      [Math.min(lng+offset, LATITUDE.MAX), Math.min(lat+offset, LONGITUDE.MAX)],
    ]
  }

  return apartments.reduce((acc, next, i) => {
    const { lat, lng } = next.coordinates

    const [
      [minLng, minLat],
      [maxLng, maxLat],
    ] = acc

    return [
      [Math.min(minLng, lng), Math.min(minLat, lat)],
      [Math.max(maxLng, lng), Math.max(maxLat, lat)],
    ]
  }, [
    [LONGITUDE.MAX, LATITUDE.MAX],
    [LONGITUDE.MIN, LATITUDE.MIN],
  ])

}

export function reducer(state: any, { type: action }: { type: string }) {
  if (action === 'idle' && (state === 'pause' || state === 'moving')) {
    return 'ready'

  } else if (action === 'pause') {
    return 'pause'

  } else if (action === 'movestart' && state !== 'pause') {
    return 'moving'

  } else {
    return state

  }
}

export function getApartmentHash(apartments: any[]) {
  return apartments.reduce((acc, apartment) => {
    return acc + apartment.id + ','
  }, '')
}
