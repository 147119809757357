'use client'
import Image from 'next/image';
import {
  PropertyCardFullWidth as FullWidthCard,
  PropertyCardHorizontal as HorizontalCard,
  PropertyCardVertical as VerticalCard
} from '@/components/devlink'
import { sendGTMEvent } from '@next/third-parties/google'

import {
  formatAddress,
  formatBedrooms,
  formatPrice,
} from './utils'

import ImageCarousel from '@/components/ImageCarousel'
import LazyLoad from '@/components/LazyLoad'
import Skeleton from '@/components/Skeleton'
import AmenityIcons from '@/components/AmenityIcons'

type CardVariant = 'horizontal' | 'vertical' | undefined

type Link = {
  href: string,
  target?: '_blank' | '_self',
}

const getImageHash = (name: string, url: string, index: number) => {
  const strippedUrl = url.replace(/https?:\/\//, '')
  return `${name}-${strippedUrl}-${index}`
}

export default function ApartmentCard({ variant = 'horizontal', count, position, ...apartment }: { variant: CardVariant, [key: string]: any }) {
  const bedrooms: number[] = []

  const startingPrice = apartment.priceSummary.reduce((acc: number, next: any) => {
    bedrooms.push(next.bedrooms)
    if (!acc && next.min?.effectiveRent.min) {
      return  next.min.effectiveRent.min

    } else if (acc && next.min?.effectiveRent.min < acc) {
      return  next.min.effectiveRent.min

    } else {
      return acc

    }
  }, undefined)

  const carousel = apartment.tour.length > 0
    ? apartment.tour
    : (apartment.carousel || [apartment.defaultPhoto])

  const props = {
    name: apartment.name,
    address: formatAddress(apartment.address),
    bedrooms: formatBedrooms(bedrooms) || 'Visit website',
    startingPrice: startingPrice ? formatPrice(startingPrice) : 'Visit website',
    linkProps: {
      href: apartment.marketingWebsiteUrl,
      target: '_blank',
      onClick: () => {
        sendGTMEvent({
          event: 'property_website_open',
          position,
          propertyName: apartment.name,
          propertyWebsiteUrl: apartment.marketingWebsiteUrl,
        })
      },
    } as Link,
    amenitySlot: <AmenityIcons names={apartment.amenityIcons || []} />,
    slideSlot: <ImageCarousel style={{}}>
      {carousel.map((image: any, index: number) => (
        <LazyLoad key={getImageHash(apartment.name, image.url, index)} placeholder={<Skeleton />}>
          <Image src={image.url} alt={image.alt} fill={true}
            className='card_slider-image'
            sizes='(max-width: 479px) 55vw, (max-width: 767px) 30vw, 35vw'
          />
        </LazyLoad>
      ))}
    </ImageCarousel>,
  }

  return (
    <>
      {count < 4 ? <FullWidthCard {...props} /> : (variant === 'vertical' ? <VerticalCard {...props} /> : <HorizontalCard {...props} />)}
    </>
  )
}

