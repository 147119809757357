import { useEffect } from 'react'

// logic copied from usehooks-ts
// added padding right for scrollbac
export default function useScrollLock({
  autoLock = true,
  target = 'body'
}: {
  autoLock?: boolean,
  target?: string
}) {

  const lock = () => {
    const elem = document.querySelector(target) as any
    if (elem) {
      elem.style.overflow = 'hidden'
      elem.style.paddingRight = '15px'
    }
  }

  const unlock = () => {
    const elem = document.querySelector(target) as any
    if (elem) {
      elem.style.overflow = 'visible'
      elem.style.paddingRight = '0px'
    }
  }

  useEffect(() => {
    if (autoLock) lock()
    return unlock
  }, [])

  return {
    lock,
    unlock,
  }
}
