export const slideFromLeft = {
  setup: (target: string) => {
    const elem = document.querySelector(target) as any
    if (!elem) return
    elem.style.transition = ['left'].map(s => `${s} 0.5s ease-in-out`).join(',')
    elem.style.left = ''
  },
  cleanup: (target: string) => {
    const elem = document.querySelector(target) as any
    if (!elem) return
    elem.style.transition = ''
    elem.style.left = ''
  },
  forward: (target: string) => {
    const elem = document.querySelector(target) as any
    if (!elem) return
    elem.style.left = '0%'
  },
  reverse: (target: string) => {
    const elem = document.querySelector(target) as any
    if (!elem) return
    elem.style.left = ''
  },
}

export const appear = {
  setup: (target: string) => {
    const elem = document.querySelector(target) as any
    if (!elem) return
    elem.style.transition = ['opacity', 'visibility'].map(s => `${s} 0.5s ease-in-out`).join(',')
    elem.style.display = 'flex'
    elem.style.visibility = 'hidden'
    elem.style.opacity = 0
  },
  cleanup: (target: string) => {
    const elem = document.querySelector(target) as any
    if (!elem) return
    elem.style.transition = ''
    elem.style.display = ''
    elem.style.visibility = ''
    elem.style.opacity = ''
  },
  forward: (target: string) => {
    const elem = document.querySelector(target) as any
    if (!elem) return
    elem.style.visibility = 'visible'
    elem.style.opacity = 1
  },
  reverse: (target: string) => {
    const elem = document.querySelector(target) as any
    if (!elem) return
    elem.style.visibility = 'hidden'
    elem.style.opacity = 0
  },
}

export const appearFromLeft = {
  setup: (target: string) => {
    const elem = document.querySelector(target) as any
    if (!elem) return
    elem.style.transition = ['left', 'opacity', 'visibility'].map(s => `${s} 0.5s ease-in-out`).join(',')
    elem.style.display = 'flex'
    elem.style.visibility = 'hidden'
    elem.style.opacity = 0
    elem.style.left = ''
  },
  cleanup: (target: string) => {
    const elem = document.querySelector(target) as any
    if (!elem) return
    elem.style.transition = ''
    elem.style.display = ''
    elem.style.visibility = ''
    elem.style.opacity = ''
    elem.style.left = ''
  },
  forward: (target: string) => {
    const elem = document.querySelector(target) as any
    if (!elem) return
    elem.style.visibility = 'visible'
    elem.style.opacity = 1
    elem.style.left = '0%'
  },
  reverse: (target: string) => {
    const elem = document.querySelector(target) as any
    if (!elem) return
    elem.style.visibility = 'hidden'
    elem.style.opacity = 0
    elem.style.left = ''
  },
}
