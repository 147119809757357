"use client";

import { useState, useMemo } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {
  SliderNext,
  SliderPrev,
} from '@/components/devlink'

import "./index.css";

const leftArrow = (onClickHandler, hasPrev) =>
  hasPrev && (
    <div onClick={onClickHandler} className="prevArrow">
      <SliderPrev />
    </div>
  );

const rightArrow = (onClickHandler, hasNext) =>
  hasNext && (
    <div onClick={onClickHandler} className="nextArrow">
      <SliderNext />
    </div>
  );

export default function Slider({ style, children: images }) {
  const [active, setActive] = useState(0);

  const visibleDots = useMemo(() => {
    const currentSlide = active
    const totalSlides = images.length

    let start = currentSlide - 2

    if (start + 5 > totalSlides) {
      start -= (start + 5) - totalSlides
    }

    if (start < 0) {
      start = 0
    }

    const results = []

    for (let i = start; i <= totalSlides - 1 && results.length < 5; i++) {
      results.push(i)
    }

    return results
  }, [active, images.length])

  const showIndicators = (onClickHandler, isSelected, index) => {
    if (isSelected) {
      return <li className="selectedIndicatorStyle" />;
    } else if (visibleDots.includes(index)) {
      return <li className="indicatorsStyle" />;
    } else {
      return;
    }
  };

  return (
    <div className="image-carousel" style={{ ...style, cursor: images.length > 1 ? 'pointer' : 'default' }}>
      <Carousel
        showStatus={false}
        showThumbs={false}
        renderArrowNext={rightArrow}
        renderArrowPrev={leftArrow}
        renderIndicator={images.length > 1 ? showIndicators : false}
        swipeable={true}
        swipeScrollTolerance={50}
        preventMovementUntilSwipeScrollTolerance={true}
        transitionTime={500}
        emulateTouch={true}
        onChange={(index) => setActive(index)}
        useKeyboardArrows={true}
      >
        {images}
      </Carousel>
    </div>
  );
}
