'use client'
import { useState, useRef } from 'react'
import { useRefinementList } from 'react-instantsearch'
import { useOnClickOutside } from 'usehooks-ts'
import {
  AdvSearchAmenitiesDropdown2 as Dropdown,
} from '@/components/devlink'
import * as _Builtin from '@/components/devlink/_Builtin'

import { FormCheckboxInput } from '@/components/Checkbox'

// see @/components/devlink/AdvSearchAmenitiesCheckboxItem
function CheckboxItem({
  as: _Component = _Builtin.ListItem,
  text = "Checkbox",
  checkboxProps = {},
  showDisabled = false,
}) {
  return (
    <_Component className="amenities-list-item">
      <_Builtin.FormCheckboxWrapper className="checkbox-field">
        <FormCheckboxInput
          className="amenity-check"
          type="checkbox"
          name="amenity-label"
          data-name="amenity-label"
          required={false}
          checked={false}
          id="amenity-label"
          inputType="custom"
          customClassName="w-checkbox-input--inputType-custom"
          {...checkboxProps}
        />
        <_Builtin.FormInlineLabel className="checkbox-label">
          {text}
        </_Builtin.FormInlineLabel>
        {showDisabled ? (
          <_Builtin.Block className="show-disabled-checkbox" tag="div" />
        ) : null}
      </_Builtin.FormCheckboxWrapper>
    </_Component>
  );
}

export function CheckboxRefinementList({
  showInline = true,
  showButton = false,
  ...props
}: any) {
  const { items, refine } = useRefinementList(props)
  const refined = items.filter(item => item.isRefined)
  const [showPopover, setShowPopover] = useState(false)

  const ref = useRef(null)
  useOnClickOutside(ref, () => setShowPopover(false))

  const dropdownProps = {
    showInline,
    showButton,
    buttonText: refined.length > 0 ? `Amenities (${refined.length})` : 'Amenities',
    showPopover,
    buttonProps: {
      onClick: () => setShowPopover(!showPopover),
    },
  }

  return (
    <div ref={ref} className='location-group'>
      <Dropdown {...dropdownProps}>
        {items.map((item, key) =>
          <CheckboxItem
            key={key}
            text={item.label}
            showDisabled={false}
            checkboxProps={{
              id: `amenity-${item.label.replaceAll(' ', '_')}`,
              value: item.label,
              checked: item.isRefined,
              onChange: () => refine(item.value),
              disabled: false,
            }}
          />,
        )}
      </Dropdown>
    </div>
  )
}
