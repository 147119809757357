'use client'
import {
  CardIconCoveredParking,
  CardIconDogPark,
  CardIconEvCharging,
  CardIconFitness,
  CardIconPet,
  CardIconPool,
  CardIconPrivateGarage,
  CardIconWashDry,
} from '@/components/devlink'

type Icons = {
  [key: string]: any
}

const icons = {
  pet: CardIconPet,
  coveredParking: CardIconCoveredParking,
  privateGarage: CardIconPrivateGarage,
  evCharging: CardIconEvCharging,
  fitness: CardIconFitness,
  pool: CardIconPool,
  washDry: CardIconWashDry,
  dogPark: CardIconDogPark,
} as Icons

const friendlyNameToIconName = (name: string): string => {
  let iconName = ''

  switch(name) {
    case "Pool":
      iconName = 'pool'
      break
    case "Covered Parking":
      iconName = 'coveredParking'
      break
    case "Garage":
      iconName = 'privateGarage'
      break
    case "Fitness Center":
      iconName = 'fitness'
      break
    case "Pet Friendly":
      iconName = 'pet'
      break
    case "Dog Park":
      iconName = 'dogPark'
      break
    case "Washer & Dryer":
      iconName = 'washDry'
      break
    case "EV Charging":
      iconName = 'evCharging'
      break
  }

  return iconName
}

export default function AmenityIcons({ names }: { names: string[] }) {
  const uniqueIcons = names.reduce((acc: string[], name: string) => {
    const iconName = friendlyNameToIconName(name)

    if (iconName && acc.indexOf(iconName) === -1) {
      return [
        ...acc,
        iconName,
      ]
    } else {
      return acc
    }
  }, []) as string[]

  return uniqueIcons.map((name: string) => {
    const Icon = icons[name]
    return <Icon key={name} />
  })
}
