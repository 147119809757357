'use client'

import { useState, useEffect } from 'react'
import { useRange } from 'react-instantsearch'

import RangeSlider from '@/components/RangeSlider'
import { formatPrice } from '@/components/ApartmentCard/utils'
import { AdvSearchRentRange } from '@/components/devlink'

import './RangeInput.css'

export function RangeInput(props: any) {
  const { start, range, canRefine, refine } = useRange(props);
  const { min = 0, max = 0 } = range;
  const [values, setValues] = useState([min, max]);

  useEffect(() => {
    const from = start[0] && Number.isFinite(start[0]) ? Math.max(min, start[0]) : min;
    const to = start[1] && Number.isFinite(start[1]) ? Math.min(max, start[1]) : max;

    setValues([from, to])
  }, [start[0], start[1], min, max]);

  return canRefine ? (
    <AdvSearchRentRange
      rangeSliderSlot={
        values[0] < values[1] ? <RangeSlider
          values={values}
          min={min}
          max={max}
          onValuesUpdated={({ values: [min, max] }) => setValues([min, max])}
          onChange={({ values: [min, max] }) => refine([min, max])}
        /> : <div />
      }
      textMin={formatPrice(values[0])}
      textMax={formatPrice(values[1])}
    />
  ) : null
}

