'use client'

import { uploadcareLoader } from '@uploadcare/nextjs-loader';
import { imgixLoader } from "@delicious-simplicity/next-image-imgix-loader";

const resolutions = [
  500,
  800,
  1080,
  1600,
  2000,
]

export default function myImageLoader({ src, width, quality }) {
  if (src.match(/cdn.lineups.io/)) {
    return uploadcareLoader({ src, width, quality })

  } else if (src.match(/imgix.net/)) {
    return imgixLoader({ src, width, quality })

  } else if (src.match(/uploads-ssl.webflow.com/) && !src.match(/.svg$/)) {

    const [_, path, ext] = src.split(/^(.*)\.([a-z]+)$/)
    const r = resolutions.reduce((acc, next) => {
      return width > acc ? next : acc
    })
    const max = resolutions[resolutions.length - 1]
    const url = r === max ? src : `${path}-p-${r}.${ext}`

    return `${url}?__ignore__=${width}`
  } else {
    // TODO: write URL transformation for filestack images (cdn.filestackcontent.com)
    return `${src}?__ignore__=${width}`

  }
}
