'use client'

import {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useReducer,
  forwardRef,
} from 'react';
import Map, { Marker, NavigationControl, Popup } from 'react-map-gl';
import type { ViewStateChangeEvent, MapEvent } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import ApartmentCard from '@/components/ApartmentCard'

import './index.css'

import {
  getBoundingBox,
  getApartmentHash,
  reducer,
} from './utils'

type Size = {
  width: number | undefined
  height: number | undefined
}

const fitBoundsOptions = {
  padding: 50,
  duration: 5 * 1000,
}

// TODO: set type for RefObject<MapRef>
function MapGl({ apartments, onMove }: { apartments: any[], onMove: () => void }, mapRef: any) {
  const [viewState, setViewState] = useState({
    longitude: -100,
    latitude: 40,
    zoom: 3.5
  });
  const [popupInfo, setPopupInfo] = useState<any>(null);
  const [state, dispatch] = useReducer(reducer, 'pause')

  const hash = getApartmentHash(apartments)
  useEffect(() => {
    dispatch({ type: 'pause' })
  }, [hash])

  useEffect(() => {
    if (state === 'moving') {
      onMove()
    }
  }, [state])

  const popup = useMemo(() => {
    return popupInfo ? (
      <Popup
        longitude={popupInfo.coordinates.lng}
        latitude={popupInfo.coordinates.lat}
        anchor="bottom"
        closeOnClick={false}
        onClose={() => setPopupInfo(null)}
      >
        <ApartmentCard variant='vertical' {...popupInfo} />
      </Popup>
    ) : null
  }, [popupInfo?.id])

  const bbox = getBoundingBox(apartments)
  useEffect(() => {
    if (mapRef?.current && apartments.length > 0) {
      mapRef.current.fitBounds(bbox, fitBoundsOptions)
    }
  }, [bbox[0][0], bbox[0][1], bbox[1][0], bbox[1][1]])

  const onLoad = useCallback((e: MapEvent) => {
    e.target.fitBounds(bbox, fitBoundsOptions)
  }, [bbox[0][0], bbox[0][1], bbox[1][0], bbox[1][1]])

  const showPopup = useCallback((apartment: any) => {
    setPopupInfo(apartment)
    const { lng, lat } = apartment.coordinates
    const offset = 64.847 * (0.50447 ** viewState.zoom)
    mapRef.current?.flyTo({center: [lng, lat + offset], duration: 2000});
  }, [viewState.zoom])

  const markers = useMemo(() => {
    return apartments.map((apartment: any) =>
      <Marker
        key={apartment.id}
        longitude={apartment.coordinates.lng}
        latitude={apartment.coordinates.lat}
        onClick={() => {
          dispatch({ type: 'pause' })
          showPopup(apartment)
        }}
      />
    )
  }, [hash, showPopup])

  return (
      <Map
        ref={mapRef}
        onLoad={onLoad}
        mapboxAccessToken={process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN}
        {...viewState}
        onMove={(e: ViewStateChangeEvent) => setViewState(e.viewState)}
        onMoveStart={dispatch}
        onIdle={dispatch}
        mapStyle="mapbox://styles/texaspacm/cjjgogt6h2dhu2rpsz0gyhxh0"
      >
        {markers}
        {popup}
        <NavigationControl showCompass={false} visualizePitch={false} position='top-left' />
      </Map>
  );
}

// TODO: set forwardRef type
export default forwardRef(MapGl);
