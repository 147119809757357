'use client'
import {
  useRef,
  useState,
  useEffect,
  useMemo,
} from 'react'
import {
  useInstantSearch,
  useGeoSearch,
  useClearRefinements,
} from 'react-instantsearch';
import { usePathname } from 'next/navigation'
import {
  AdvSearchComponentsWrapper,
  RedoSearchButton,
} from '@/components/devlink'
import type { MapRef } from 'react-map-gl';
import qs from 'qs'
import { useRouter } from 'next/navigation'
import { useMediaQuery } from 'usehooks-ts'
import dynamic from 'next/dynamic'

import { SearchComponents } from './SearchComponents'
import { getApartmentHash } from '@/components/Map/utils'

import { useModal } from '@/components/hooks'
import Map from '@/components/Map'

const generateText = (n: number) => `Show ${n} Apartment${n === 1 ? '' : 's'}`

const indexName = process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME || ''

export function SearchBar({
  apartments = [],
  showDesktopFilterButton,
}: {
  apartments: any[],
  showDesktopFilterButton?: boolean,
}) {
  const router = useRouter()
  const pathname = usePathname()
  const { uiState, results, refresh } = useInstantSearch()
  const allMarkets = uiState[indexName].refinementList?.allMarkets
  const textMarketName = useMemo(() => {
    return (allMarkets ? allMarkets[0] : 'Search ...').replace('\\\- ', '')
  }, [allMarkets])

  useEffect(() => {
    refresh()
  }, [])

  const { refine, clearMapRefinement } = useGeoSearch()
  const { refine: clearAllMarkets } = useClearRefinements({ includedAttributes: ['allMarkets'] })

  const [redoEnabled, setRedoEnabled] = useState(false)

  const hash = getApartmentHash(apartments)
  useEffect(() => {
    setRedoEnabled(false)
  }, [hash])

  const mapRef = useRef<MapRef>(null)

  const handleRedoSearch = (e: any) => {
    e.preventDefault()
    if (mapRef?.current) {
      const bounds = mapRef.current.getBounds()
      if (pathname !== '/search/') {
        const query = qs.stringify({
          [indexName]: {
            geoSearch: {
              boundingBox: [
                bounds._ne.lat, bounds._ne.lng,
                bounds._sw.lat, bounds._sw.lng,
              ].join(',')
            },
          },
        })
        router.push(`/search/?${query}`)

      } else {
        clearAllMarkets()
        refine({ northEast: bounds._ne, southWest: bounds._sw })
        setRedoEnabled(false)

      }
    }
  }

  const isMobile = useMediaQuery('(max-width: 479px)')

  const { toggle } = useModal('.map---position-100-off-page')
  const [loaded, setLoaded] = useState(false)
  const toggleMap = (e: any) => {
    toggle(e)
    setLoaded(true)
  }
  // @ts-ignore
  const { toggle: toggleFilter } = useModal('.modal---position-100-off-page-0', isMobile ? 'appearFromLeft' : 'appear')

  const { configure, ...searchState } = uiState[indexName]
  const query = qs.stringify({ [indexName]: searchState })
  const href = `/search/?${query}`
  const searchButtonProps = useMemo(() => ({
    onClick: pathname === '/search/' ? toggleFilter : undefined,
    prefetch: false,
    options: { href }
  }), [pathname, href])

  const showAdvSearch = useMediaQuery('(min-width: 1024px)') && pathname === '/search/'

  const children = <SearchComponents />

  const props = {
    textMarketName,
    redoButtonSlot: <RedoSearchButton
      showShowDisabled={!redoEnabled}
      showShowEnabled={redoEnabled}
      buttonProps={{ onClick: handleRedoSearch }}
    />,
    showNoResults: apartments.length === 0,
    resetSearchRuntimeProps: {
      onClick: () => clearMapRefinement(),
    },
    mapSlot: !isMobile || loaded
      ? <Map apartments={apartments} ref={mapRef} onMove={() => setRedoEnabled(true)} />
      : <div />,
    searchButtonProps,
    textSearchButton: generateText(results.nbHits),
    showDesktopFilterButton: !showAdvSearch,
    showAdvSearch,
    toggleFilterButtonProps: { onClick: toggleFilter },
    toggleMapButtonProps: { onClick: toggleMap },
    modalChildren: <SearchComponents variant='inline' />,
  }

  return <AdvSearchComponentsWrapper {...props}>
    {showAdvSearch ? <SearchComponents /> : <div />}
  </AdvSearchComponentsWrapper>
}
