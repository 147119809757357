'use client'

import { useInView } from 'react-intersection-observer'

type LazyLoadProps = {
  children: React.ReactNode
  triggerOnce?: boolean
  rootMargin?: string
  placeholder?: React.ReactNode
}

const LazyLoad = ({ children, triggerOnce = true, rootMargin = '200px 0px', placeholder = null, ...props }: LazyLoadProps) => {
  const { ref, inView } = useInView({
    triggerOnce,
    rootMargin,
    ...props
  })

  return <>
    <div ref={ref} />
    {inView ? children : placeholder}
  </>
}

export default LazyLoad
