'use client'
import algoliasearch from 'algoliasearch/lite'
import {
  Configure,
  HitsPerPage,
} from 'react-instantsearch'
import type { UiState } from 'instantsearch.js'
import { Suspense } from 'react'
import { InstantSearchNext } from 'react-instantsearch-nextjs';

import './InstantSearch.css'

const appId = process.env.NEXT_PUBLIC_ALGOLIA_APP_ID || ''
const key = process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY || ''
const client = algoliasearch(appId, key)

const indexName = process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME

export function InstantSearch({
  routing,
  initialUiState,
  children
}: {
  routing?: boolean,
  initialUiState?: UiState,
  children: React.ReactNode
}) {
  return (
    <Suspense>
      <InstantSearchNext
        searchClient={client}
        indexName={indexName}
        routing={routing}
        initialUiState={initialUiState}
        future={{
          preserveSharedStateOnUnmount: true,
        }}
      >
        <Configure
          analytics
          clickAnalytics
          sortFacetValuesBy='alpha'
        />
        <HitsPerPage
          className='hidden'
          items={[
            { label: '25', value: 25, default: true },
          ]}
        />
        {children}
      </InstantSearchNext>
    </Suspense>
  )
}

