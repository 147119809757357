'use client'
import { useConnector } from 'react-instantsearch';
import connectAutocomplete from 'instantsearch.js/es/connectors/autocomplete/connectAutocomplete';

type Index = {
  indexName: string
  hits: any[]
}

type AutocompleteConnector = {
  indices: Index[]
  refine: (value: string) => void
}

// Connect the InstantSearch.js `connectAutocomplete` connector to your component
export function useAutocomplete(): AutocompleteConnector {
  //@ts-ignore
  return useConnector(connectAutocomplete);
}
