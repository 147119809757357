import { useMediaQuery } from 'usehooks-ts'

import { RefinementList } from './RefinementList'
import { ButtonGroupRefinementList } from './ButtonGroupRefinementList'
import { RangeInput } from './RangeInput'
import { CheckboxRefinementList } from './CheckboxRefinementList'

const formatLabel = (label: string) => label === '0' ? 'Studio' : label

export function SearchComponents({ variant }: { variant?: string }) {
  const isMobile = useMediaQuery('(max-width: 479px)')

  const showInline = variant === 'inline' || isMobile

  return <>
    <RefinementList attribute='allMarkets' />
    <ButtonGroupRefinementList
      attribute='floorplan.bedrooms'
      sortBy={['name:asc']}
      formatLabel={formatLabel}
    />
    <CheckboxRefinementList
      attribute='amenityIcons'
      operator='and'
      limit={25}
      sortBy={['name:asc']}
      showInline={showInline}
      showButton={!showInline}
    />
    <RangeInput attribute='rent' />
    <div style={{ display: 'none' }}>
      <RefinementList attribute='type' />
    </div>
  </>
}
